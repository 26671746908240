@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@100;400;500;700&display=swap");

label {
  font-weight: 700;
  font-size: 15px;
  color: #686868;
  margin-bottom: 5px;
}

b,
strong {
  color: #5e5e5e;
}

a {
  color: #ff9a00;
  text-decoration: none;
  background-color: transparent;
}

a:hover {
  color: #ff8c00;
  text-decoration: none;
}

.text-orange {
  color: #f6a23a;
}

/* Bootstrap Overwrites */

.form-control,
.custom-select {
  border: 1px solid #dee2e7;
  border-radius: 5px;
  height: 45px;
  font-size: 15px;
}

.form-control::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #8f8f8f;
  opacity: 1;
  /* Firefox */
}

.form-control:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: #8f8f8f;
}

.form-control::-ms-input-placeholder {
  /* Microsoft Edge */
  color: #8f8f8f;
}

.btn-sm {
  font-size: 11px;
}

.btn-link {
  /* font-weight: 400; */
  color: #ff9a00;
  text-decoration: none;
}

.btn-link:hover {
  /* font-weight: 400; */
  color: #ffb300;
  text-decoration: none;
}

.btn-primary {
  color: #fff;
  background-color: #ff9a00;
  border-color: #ff9a00;
  /* height: 45px; */
}

.btn-primary:focus,
.btn-primary:hover {
  color: #fff;
  background-color: #ff8c00;
  border-color: #ff8c00;
}

.btn-primary.disabled,
.btn-primary:disabled {
  color: #fff;
  background-color: #ff8c00;
  border-color: transparent;
  opacity: 0.6;
}

.btn-primary:not(:disabled):not(.disabled).active,
.btn-primary:not(:disabled):not(.disabled):active,
.show > .btn-primary.dropdown-toggle {
  color: #fff;
  background-color: #ff8c00;
  border-color: #ff8c00;
}

.btn-outline-primary {
  border-color: #ff8c00;
  color: #ff8c00;
  background-color: transparent !important;
}

.btn-outline-primary:focus,
.btn-outline-primary:hover {
  background-color: #ff8c00;
  color: #ff9a00;
  border-color: #ff9a00;
  outline: none;
  box-shadow: none;
}

.btn-order-confirm {
  font-size: 13px;
  height: 40px;
}

.bg-light {
  background-color: #f9f9f9 !important;
}

.card {
  border: none;
  border-radius: 10px;
}

.card-footer {
  background-color: white;
}

/* checkbox round */
.checkbox-round {
  width: 1.3em;
  height: 1.3em;
  background-color: white;
  border-radius: 50%;
  vertical-align: middle;
  border: 1px solid #555;
  appearance: none;
  -webkit-appearance: none;
  outline: none;
  cursor: pointer;
  margin-right: 12px;
}

.checkbox-round:checked {
  background-color: #ff9a00;
}

/* checkbox square */
.checkbox-square {
  width: 1.3em;
  height: 1.3em;
  background-color: white;
  vertical-align: middle;
  border: 1px solid #555;
  appearance: none;
  -webkit-appearance: none;
  outline: none;
  cursor: pointer;
  margin-right: 12px;
}

.checkbox-square:checked {
  background-color: #ff9a00;
}

/* No data found  */
.no-data-holder {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: rgb(255 255 255);
  padding: 1.5rem;
  text-align: center;
  border-radius: 10px;
}

.no-data-holder img {
  width: 140px;
  margin: 10px 0;
}

.no-data-holder .icon {
  font-size: 2rem;
  color: #828282;
}

.no-data-msg {
  margin-top: 1rem;
  margin-bottom: 0.5rem;
  color: #828282;
  font-size: 1rem;
  font-weight: normal;
}

.link-icon {
  font-size: 20px;
}

.load-content-wrapper {
  display: flex;
  justify-content: center;
  align-content: center;
  padding: 2rem 1rem;
  margin-bottom: 1rem;
  color: #fff3e1;
  background-color: #ed8f00;
  border-radius: 10px;
}

.load-content-text {
  /* padding: 1rem; */
  min-height: 4rem;
}

.load-content-img {
  height: 30px;
}

/* simple - enter transition 300ms, exit 150ms */

.fade-appear,
.fade-enter {
  opacity: 0;
  z-index: 1;
}

.fade-appear-active,
.fade-enter.fade-enter-active {
  opacity: 1;
  transition: opacity 300ms linear 150ms;
}

.fade-exit {
  opacity: 1;
}

.fade-exit.fade-exit-active {
  opacity: 0;
  transition: opacity 150ms linear;
}

.mapDiv {
  width: 100%;
  height: 50%;
  position: absolute;
}

.header-title {
  font-weight: bold;
  color: #ffffff;
  /* text-transform: capitalize; */
  font-size: 18px;
  margin: 3px 0;
}

/* 
.header-logo {
  width: auto;
  height: 34px;
  margin-left: 1rem;
} */

.header-home-icon {
  font-size: 1.5rem;
  color: #ffffff;
}

.header-logo img {
  height: 34px;
  width: auto;
}

.popover-box {
  display: block;
  position: absolute;
  padding: 1rem 1.2rem;
  border-radius: 0.6rem;
  background-color: #fff;
  bottom: 18px;
  left: -108px;
  z-index: 9999999;
  min-width: 240px;
  box-shadow: 0px 2px 7px rgba(0, 0, 0, 0.2);
}

.popover-box::after {
  content: "";
  position: absolute;
  bottom: -15px;
  left: 45%;
  border-style: solid;
  border-width: 15px 15px 0;
  border-color: #ffffff transparent;
  display: block;
  width: 0;
  z-index: 1;
}

.shop-name {
  font-size: 0.9rem;
  display: inline-block;
  font-weight: bold;
  color: #525252;
  margin: 0 0 1rem;
  line-height: normal;
}

.shop-adrs {
  font-size: 0.8rem;
  color: #777;
  margin-bottom: 15px;
}

.button-link {
  display: block;
  text-transform: uppercase;
  font-size: 0.8rem;
  font-weight: bold;
  color: #ff6700;
  padding: 0.5rem 1rem;
  border-radius: 0.3rem;
  text-align: center;
  background-color: #f9f9f9;
  text-decoration: none;
  cursor: pointer;
  transition: all 0.5s;
}

.button-link:hover {
  color: #2a4285;
}

/* nav header  */
.nav-header {
  position: fixed;
  width: 100%;
  height: 65px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  background-color: #ff9a00;
  color: #fff;
  z-index: 999;
}

.nav-header div:first-child {
  padding: 4px 0;
  font-size: 13px;
  cursor: pointer;
}

.nav-header .nav-header-icon {
  font-size: 24px;
  color: #fff;
  margin: 0 3px 0 0;
  overflow: visible;
}

.partial-header-logo {
  position: absolute;
  left: 45.5%;
  top: 12px;
  width: 50px;
  height: auto;
}

.product-detail-modal-header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.bottom-fixed-btn {
  position: fixed;
  bottom: 1rem;
  /* margin: 0 20px 20px; */
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 10px 12px;
  background-color: #ff9a00;
  color: #fff;
  border: none;
  border-radius: 5px;
  z-index: 9999999999;
}

.map-img {
  width: 100%;
  height: auto;
  margin-bottom: 1.5rem;
}

/* .shop-channels {
  background: #ffffff;
  border: 1px solid #dedede;
  padding: 3px 9px;
  margin-bottom: 5px;
}

.shop-channels.active {
  border: 1px solid #ff6700;
  color: #ff6700;
  padding: 3px 9px;
  margin-bottom: 5px;
} */

/* Offcanvas Menu Css */

.offcanvas-title {
  /* display: flex;
  justify-content: center;
  align-items: center; */
  padding: 16px 20px;
  /* background: linear-gradient(153deg, #ff9a00 0%, #ff8c00 35%, #ffb300 100%); */
  background-color: #343434;
  color: #fff;
  font-size: 20px;
  border-bottom: 0.5px solid #dedede;
}

.offcanvas-menu_title {
  font-size: 13px;
  margin-left: 1.5rem;
  font-weight: bold;
  color: #ffffff;
  text-transform: uppercase;
  letter-spacing: 0.3rem;
}

.offcanvas-menu_link {
  display: block;
  padding: 15px 20px;
  color: #565353;
  font-size: 16px;
  margin-top: 6px;
}

.offcanvas-menu_link:focus,
.offcanvas-menu_link:hover {
  text-decoration: none;
  background-color: #fffaf6;
  cursor: pointer;
}

.offcanvas-menu_icon {
  color: #ff9a00;
  margin-top: -5px;
  margin-right: 15px;
  margin-left: 0;
  background-color: #fffaf6;
  padding: 5px 6px;
  border-radius: 10px;
}

.offcanvas-menu_icon .icon {
  font-size: 19px;
  margin-top: -4px;
}

.offcanvas-menu_logo {
  width: auto;
  height: 33px;
}

.bottom-line {
  position: fixed;
  bottom: 0;
  width: 100%;
  height: 100px;
}

.pagination {
  justify-content: center;
}

.page-link {
  color: #ff9a00;
  background-color: #fff;
  border: 1px solid #dee2e6;
  margin: 0 3px;
}

.page-item.active .page-link {
  z-index: 3;
  color: #fff;
  background-color: #ff6b00;
  border-color: #ff6b00;
}

.qty-input {
  width: 90px !important;
  height: 30px !important;
  border: none !important;
  z-index: -1;
  background: #f9f9f9;
  border-radius: 2rem !important;
  padding-right: 1rem !important;
  padding-left: 1rem !important;
  font-size: 15px !important;
  pointer-events: none;
}

.qty-input:focus,
.qty-input:hover {
  outline: none !important;
}

.qty-input-holder b {
  border-radius: 2rem !important;
  background: #fff !important;
  cursor: pointer !important;
  width: 120px;
}

.bottom-fixed-area {
  width: 100%;
  position: fixed;
  bottom: 0;
  padding: 10px;
  background-color: white;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.bottom-fixed-area .qty-input-holder b {
  background: #ff9a00 !important;
  color: #fff;
}

.bottom-fixed-area .btn-fix {
  padding: 0.8rem 2rem;
}

.block-label {
  font-weight: bold;
  color: #676767;
  font-size: 17px;
  margin-bottom: 3px;
}

/* Loading CSS */
.loading-ellipsis {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 20px;
}

.loading-ellipsis div {
  position: absolute;
  width: 13px;
  height: 13px;
  border-radius: 50%;
  background: #ff9a00;
  animation-timing-function: cubic-bezier(0, 1, 1, 0);
}

.loading-ellipsis div:nth-child(1) {
  left: 8px;
  animation: loading-ellipsis1 0.6s infinite;
}

.loading-ellipsis div:nth-child(2) {
  left: 8px;
  animation: loading-ellipsis2 0.6s infinite;
}

.loading-ellipsis div:nth-child(3) {
  left: 32px;
  animation: loading-ellipsis2 0.6s infinite;
}

.loading-ellipsis div:nth-child(4) {
  left: 56px;
  animation: loading-ellipsis3 0.6s infinite;
}

@keyframes loading-ellipsis1 {
  0% {
    transform: scale(0);
  }

  100% {
    transform: scale(1);
  }
}

@keyframes loading-ellipsis3 {
  0% {
    transform: scale(1);
  }

  100% {
    transform: scale(0);
  }
}

@keyframes loading-ellipsis2 {
  0% {
    transform: translate(0, 0);
  }

  100% {
    transform: translate(24px, 0);
  }
}

@media (min-width: 480px) {
  .fluid,
  .coupon-detail-grid {
    flex-direction: row;
  }

  .fluid__instructions,
  .coupon-detail-info {
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
  }

  .fluid__image-container,
  .coupon-detail_img {
    flex: 0 0 50%;
    margin: 0;
    z-index: 1;
  }
}

.content-section {
  padding: 5rem 0;
  min-height: 100vh;
  background-color: #f9f9f9;
}

div.closed-shop {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  background: rgba(0, 0, 0, 0.349);
  width: 100%;
  height: 100%;
  z-index: 10;
  /* top: 4rem; */
  left: 0;
  position: fixed;
}

.closed-warning-card {
  margin-top: 7.65rem;
  display: block;
  text-align: center;
  padding: 2rem;
  background-color: #ffffff;
  border-radius: 8px;
}

.closed-warning-card span {
  font-size: 14px;
}

.closed-warning-card .btn .icon {
  font-size: 1rem;
  margin-top: -4px;
}

.closed-icon {
  width: 80px;
  margin-bottom: 1rem;
}

/*======= Map Styling ============*/

.gmnoprint a,
.gmnoprint span,
.gmnoprint {
  display: none;
}

.gmnoprint div {
  background: none !important;
}

#GMapsID div div a div img {
  display: none;
}

.login-text {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.label-remember-term {
  color: #fff;
  font-size: 10px;
  text-transform: uppercase;
  font-weight: 300;
}

.filter-dropdown button {
  background: none;
  box-shadow: none;
  border: none;
  padding: 3px 10px;
}

.filter-dropdown button img {
  width: 20px;
  height: auto;
}

/* .btn-secondary {
  padding: 10px 30px;
  font-weight: bold;
  height: 45px;
} */

.filter-dropdown .btn-secondary:not(:disabled):not(.disabled).active:focus,
.filter-dropdown .btn-secondary:not(:disabled):not(.disabled):active:focus,
.filter-dropdown .show > .btn-secondary.dropdown-toggle:focus {
  box-shadow: none;
  background: none;
  border: none;
  background-color: transparent;
}

.filter-dropdown .btn-secondary:focus,
.filter-dropdown .btn-secondary:hover {
  box-shadow: none;
  background: none;
  border: none;
  background-color: transparent;
}

.filter-dropdown .dropdown-menu {
  position: absolute;
  left: -30px !important;
  padding: 3px;
  border: none;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

/* Loading Spinner CSS */
.loading {
  position: fixed;
  z-index: 999;
  height: 2em;
  width: 2em;
  overflow: visible;
  margin: auto;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}

.loading:before {
  content: "";
  display: block;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.3);
  opacity: 0.8;
}

.loading .letter-holder {
  padding: 16px;
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.loading .letter {
  float: left;
  font-size: 24px;
  color: white;
  font-weight: 600;
  font-family: sans-serif;
}

/* Transparent Overlay */

.loading .letter {
  animation-name: loadingF;
  animation-duration: 1.6s;
  animation-iteration-count: infinite;
  animation-direction: linear;
}

.loading .l-1 {
  animation-delay: 0.48s;
}

.loading .l-2 {
  animation-delay: 0.6s;
}

.loading .l-3 {
  animation-delay: 0.72s;
}

.loading .l-4 {
  animation-delay: 0.84s;
}

.loading .l-5 {
  animation-delay: 0.96s;
}

.loading .l-6 {
  animation-delay: 1.08s;
}

.loading .l-7 {
  animation-delay: 1.2s;
}

.loading .l-8 {
  animation-delay: 1.32s;
}

.loading .l-9 {
  animation-delay: 1.44s;
}

.loading .l-10 {
  animation-delay: 1.56s;
}

/* Animation */

@keyframes loadingF {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

.alert-icon {
  color: #676767;
  background: #d0d0d0;
  width: 45px;
  height: 45px;
  border-radius: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 10px 0;
}

.alert-icon.success {
  color: #0c940c;
  background: #b6ffb6;
}

.alert-icon.warning {
  color: #f79605;
  background: #f7dfa9;
}

.alert-icon.danger {
  color: #f70525;
  background: #ffb8c1;
}

.alert-icon .icon {
  font-size: 1.5rem;
}

.badge {
  display: inline-block;
  padding: 0.45em 0.8em;
  font-size: 75%;
  font-weight: bold;
  line-height: 1.2;
}

/* Modal CSS */

.modal-custom .modal-content {
  height: auto;
}

.modal-custom .modal-content .modal-body {
  /* display: flex; */
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 1rem;
  /* text-align: center; */
}

/* .modal-dialog.modal-custom.product-list-modal {
  width: 500px;
} */

.modal-custom.product-list-modal .modal-body {
  padding-right: 0.25rem;
  padding-left: 0.25rem;
}

.modal-custom.product-list-modal .product-list-item {
  border: 1px solid #dedede;
}

.modal .modal-dialog {
  position: relative;
  min-width: 320px;
  margin: auto;
  pointer-events: none;
}

.modal-header {
  padding: 5px 20px;
  border-bottom: none;
}

.modal-icon {
  width: 35px;
  height: 35px;
  margin-bottom: 0.8rem;
}

.modal-text {
  color: #676767;
  font-weight: normal;
  font-size: 14px;
}

.modal-content {
  border-radius: 10px;
}

.modal-message {
  color: #676767;
  font-weight: 400;
  font-size: 14px;
  text-align: center;
  margin-top: 10px;
}

.close {
  position: absolute;
  right: 6px;
  top: 5px;
  width: 18px;
  height: 18px;
  opacity: 0.3;
  cursor: pointer;
}

.close:hover {
  opacity: 1;
}

.modal-header .close {
  padding: 0;
  margin: 0;
}

.close:before {
  transform: rotate(45deg);
}

.close:after {
  transform: rotate(-45deg);
}

.modal-close-icon {
  position: absolute;
  right: 10px;
  top: 5px;
  cursor: pointer;
}

.btn.disabled,
.btn:disabled {
  opacity: 0.35;
  cursor: not-allowed;
}

.shop-info-header {
  display: flex;
  align-items: center;
  padding: 12px;
  border-radius: 6px;
  background-color: #ffffff;
  margin-bottom: 1rem;
  box-shadow: 2px 2px 3px rgb(0 0 0 / 3%);
}

.shop-info-header img {
  width: 45px;
  height: auto;
  padding: 3px;
  background-color: #f7f7f7;
  border-radius: 6px;
  margin-right: 1rem;
}

.shop-info-header .name {
  color: #777777;
  font-size: 16px;
  margin: 0;
}

#loading {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
  opacity: 0.7;
  background-color: #fff;
  z-index: 999999;
}

#loading-image {
  width: 80px;
  height: auto;
  z-index: 100;
  margin-bottom: 1rem;
}

#small-loading {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 10vh;
  opacity: 0.7;
  z-index: 999999;
}

#small-loading-image {
  width: 50px;
  height: auto;
  z-index: 100;
  margin-bottom: 1rem;
}

.content-loading {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 0;
  height: 100vh;
  z-index: 999999;
  width: 100%;
}

.content-loading img {
  width: 40px;
  height: auto;
  z-index: 100;
  margin-bottom: 1rem;
}

/* loading text dots */
.loading-text {
  font-weight: bold;
  font-size: 14px;
  color: #bbbbbb;
}

.loading-text:after {
  overflow: hidden;
  display: inline-block;
  vertical-align: bottom;
  -webkit-animation: ellipsis steps(4, end) 900ms infinite;
  animation: ellipsis steps(4, end) 900ms infinite;
  content: "\2026";
  /* ascii code for the ellipsis character */
  width: 0px;
}

@keyframes ellipsis {
  to {
    width: 1.25em;
  }
}

@-webkit-keyframes ellipsis {
  to {
    width: 1.25em;
  }
}

/* New CSS */

/* Header CSS */

.toggle-icon {
  width: 25px;
  height: auto;
}

.home__content-info {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  /* height: 90vh; */
  padding: 0 2rem;
  /* margin-bottom: 2rem; */
}

.promotion_banner {
  margin-top: 5.4rem;
  margin-bottom: 3rem;
}

.home__content-info .btn {
  position: relative;
  font-size: 1.1rem;
  letter-spacing: 0.03rem;
  height: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-transform: uppercase;
  transition: all 0.3s;
}

.home_btn-icon {
  position: absolute;
  top: 15px;
  left: 1rem;
  font-size: 1.8rem;
}

.rating {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.rating .icon {
  color: #676767;
  font-size: 22px;
}

.rating span {
  color: #676767;
  font-size: 22px;
  font-weight: bold;
}

/* Pick Up CSS */

.pick-up__info,
.dine-in__info {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.block-sub__label {
  color: #676767;
  font-size: 20px;
  font-weight: 700;
  margin-top: 10px;
}

.store-icon-holder {
  border: none;
  margin-right: 16px;
}

.store-icon {
  font-size: 25px;
  color: #ff9a00;
}

.store-icon-img {
  width: 55px;
  border-radius: 8px;
  padding: 5px;
  background-color: #fff;
  box-shadow: 0 2px 2px rgb(0 0 0 / 25%);
}

.homepage-shop-name {
  font-size: 16px;
  color: #4c4c4c;
  font-weight: bold;
  margin: 0;
}

.pickup-time-icon {
  font-size: 21px;
  color: #ff6b00;
}

.text-yellow {
  color: #ff6b00 !important;
}

.order-detail-content {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: wrap;
}

.order-detail-content span:first-child {
  color: #111111;
}

.order-countdown-wrapper {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0;
  margin-bottom: 10px;
}

.order-countdown-wrapper-waiting {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0;
  margin-bottom: 10px;
}

.order-countdown-wrapper .cd-span {
  padding: 0 5px;
  color: #ff6700;
  font-size: 21px;
  font-weight: bold;
}

.order-countdown-wrapper_waiting .cd-span {
  padding: 0 5px;
  color: #fff;
  font-size: 21px;
  font-weight: bold;
}

.order-countdown-wrapper .cd-span.label {
  font-size: 12px;
  text-transform: capitalize;
  padding: 4px 6px 0 0 !important;
  font-weight: normal !important;
}

.order-countdown-wrapper-waiting .cd-span.label {
  font-size: 12px;
  text-transform: capitalize;
  padding: 4px 6px 0 0 !important;
  font-weight: normal !important;
}

.change-password-form .form-group .icon {
  color: #888888;
  position: absolute;
  right: 1rem;
  top: 2.7rem;
  cursor: pointer;
}

.warning-icon {
  width: 50px !important;
  height: 50px !important;
}

.shop-on-map {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0.5rem 1rem;
}

.shop-on-map .image {
  width: 80px;
  height: 80px;
  padding: 6px;
  border-radius: 50%;
  background-color: #ffffff;
  box-shadow: 0 2px 8px rgb(0 0 0 / 16%);
}

.shop-on-map .image img {
  width: 100%;
  height: auto;
  border-radius: 50%;
}

.shop-on-map .name {
  font-weight: bold;
  font-size: 18px;
  color: #676767;
  margin: 15px 0;
  text-align: center;
}

.no-internet-content {
  min-height: 90vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 2rem 5rem;
  text-align: center;
}

.no-internet-icon {
  width: 80px;
  height: auto;
  margin-bottom: 2rem;
}

.my-location-icon {
  color: #ff9a00 !important;
  font-size: 26px !important;
  margin-right: 10px !important;
}

/* Media Queries */

@media (min-width: 576px) {
  .modal-dialog {
    max-width: 700px;
  }
}

@media only screen and (max-width: 767.98px) {
  .bottom-fixed-btn {
    width: 92% !important;
  }

  .modal-dialog.modal-custom.product-list-modal {
    width: 360px;
  }
}

@media (min-width: 768px) {
  .bottom-fixed-btn {
    width: 92%;
  }
}

@media only screen and (max-width: 992px) {
  .bottom-fixed-btn {
    width: 90%;
  }
}

@media (min-width: 1200px) {
  .bottom-fixed-btn {
    width: 82% !important;
  }

  .modal-dialog.modal-custom.product-list-modal {
    width: 500px;
  }
}

.payment-qr-code {
  width: auto;
  height: 300px;
}

.payment-image {
  width: auto;
  height: 75px;
}

.order-type.pos {
  border-color: #039b47;
  color: #039b47;
}

.order-type.pick-up {
  border-color: #95049c;
  color: #95049c;
}

.order-type.delivery {
  border-color: #e53a29;
  color: #e53a29;
}

.order-type.dine-in {
  border-color: #0051ff;
  color: #0051ff;
}

.order-item_underline {
  border-bottom: 2px dashed #ebebeb;
  margin: 1rem 0;
}

/* .notification overwrites  */

.notification-container {
  box-sizing: border-box;
  position: fixed;
  top: auto;
  bottom: 1rem;
  left: 0;
  z-index: 999999;
  width: 320px;
  padding: 0px 15px;
  max-height: calc(100% - 30px);
  overflow-x: hidden;
  overflow-y: auto;
}

.notification {
  box-sizing: border-box;
  padding: 15px 15px 15px 58px;
  border-radius: 8px;
  color: #fff;
  background-color: #ccc;
  box-shadow: 0 0 12px rgba(0, 0, 0, 0.18);
  cursor: pointer;
  font-size: 0.75em;
  line-height: 1.2em;
  position: relative;
  opacity: 0.9;
  margin-top: 15px;
}

.notification .title {
  font-size: 1.2em;
  line-height: 1.2em;
  font-weight: bold;
  margin: 0 0 5px 0;
}

.notification:hover,
.notification:focus {
  opacity: 1;
}

.notification-enter {
  visibility: hidden;
  transform: translate3d(100%, 0, 0);
}

.notification-enter.notification-enter-active {
  visibility: visible;
  transform: translate3d(0, 0, 0);
  transition: all 0.4s;
}

.notification-leave {
  visibility: visible;
  transform: translate3d(0, 0, 0);
}

.notification-leave.notification-leave-active {
  visibility: hidden;
  transform: translate3d(100%, 0, 0);
  transition: all 0.4s;
}

.notification:before {
  position: absolute;
  top: 50%;
  left: 15px;
  margin-top: -14px;
  display: block;
  font-family: "Notification";
  width: 24px;
  height: 24px;
  font-size: 24px;
  text-align: center;
  line-height: 24px;
}

.notification-info {
  background-color: #ddf7ff;
  color: #2f96b4;
}

.notification-info:before {
  content: "";
}

.notification-success {
  background-color: #e9ffe9;
  color: #51a351;
}

.notification-success:before {
  content: url("./assets/image//logo_small.png");
}

.notification-warning {
  background-color: #fff3e2;
  color: #f89406;
}

.notification-warning:before {
  content: "";
}

.notification-error {
  background-color: #ffe7e6;
  color: #bd362f;
}

.notification-error:before {
  content: "";
}

.pin {
  width: 25px;
  height: 25px;
  border-radius: 50% 50% 50% 0;
  /* background: #db0202; */
  background-color: #ff6500;
  position: absolute;
  -webkit-transform: rotate(-45deg);
  -moz-transform: rotate(-45deg);
  -o-transform: rotate(-45deg);
  -ms-transform: rotate(-45deg);
  transform: rotate(-45deg);
  left: 50%;
  top: 50%;
  margin: -20px 0 0 -20px;
  -webkit-animation-name: bounce;
  -moz-animation-name: bounce;
  -o-animation-name: bounce;
  -ms-animation-name: bounce;
  animation-name: bounce;
  -webkit-animation-fill-mode: both;
  -moz-animation-fill-mode: both;
  -o-animation-fill-mode: both;
  -ms-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-animation-duration: 1s;
  -moz-animation-duration: 1s;
  -o-animation-duration: 1s;
  -ms-animation-duration: 1s;
  animation-duration: 1s;
}

.pin:after {
  content: "";
  width: 9px;
  height: 9px;
  margin: 8px 0 0 8px;
  background: #ffffff;
  position: absolute;
  border-radius: 50%;
}

.pulse {
  background: #db0202;
  border-radius: 50%;
  height: 9px;
  width: 9px;
  position: absolute;
  left: 50%;
  top: 50%;
  margin: 11px 0px 0px -12px;
  -webkit-transform: rotateX(55deg);
  -moz-transform: rotateX(55deg);
  -o-transform: rotateX(55deg);
  -ms-transform: rotateX(55deg);
  transform: rotateX(55deg);
  z-index: -2;
}

.pulse:after {
  content: "";
  border-radius: 50%;
  height: 40px;
  width: 40px;
  position: absolute;
  margin: -16px 0 0 -16px;
  -webkit-animation: pulsate 1s ease-out;
  -moz-animation: pulsate 1s ease-out;
  -o-animation: pulsate 1s ease-out;
  -ms-animation: pulsate 1s ease-out;
  animation: pulsate 1s ease-out;
  -webkit-animation-iteration-count: infinite;
  -moz-animation-iteration-count: infinite;
  -o-animation-iteration-count: infinite;
  -ms-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
  opacity: 0;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
  filter: alpha(opacity=0);
  -webkit-box-shadow: 0 0 1px 2px #db0202;
  box-shadow: 0 0 1px 2px #db0202;
  -webkit-animation-delay: 1.1s;
  -moz-animation-delay: 1.1s;
  -o-animation-delay: 1.1s;
  -ms-animation-delay: 1.1s;
  animation-delay: 1.1s;
}

.otp-input-root input {
  width: 38px !important;
  height: 38px !important;
}

.badge-primary {
  color: #ff9a00;
  background-color: #ffeee0;
}

.badge-blue {
  color: #217cdc;
  background-color: #d6ebff;
}

.badge-green {
  color: #008631;
  background-color: #d9fae5;
}

.badge-danger {
  color: #ff5252;
  background-color: #fcdede;
}

.badge-info {
  color: #09bcdb;
  background-color: #d4f9ff;
}

.badge-purple {
  color: #b10ccf;
  background-color: #eccced;
}

/* qr scanner styles  */
.qr-scanner {
  position: relative;
}

.scanner-frame {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 999999;
  background-color: rgba(0, 0, 0, 0.333);
}

.scanner-frame .scanner-border {
  display: block;
  width: 250px;
  height: 250px;
  border-radius: 12px;
  border: 2px dotted #f3fff3;
}

.scanner-frame .qr-page-back-link {
  position: absolute;
  top: 15px;
  left: 20px;
  color: #ffffff;
  font-size: 14px;
}

/* /////////////////////////////////////////////////////////////////////////////////////////////////////////////s */

@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@100;400;500;700&display=swap");

label {
  font-weight: 700;
  font-size: 15px;
  color: #686868;
  margin-bottom: 5px;
}

b,
strong {
  color: #5e5e5e;
}

a {
  color: #ff9a00;
  text-decoration: none;
  background-color: transparent;
}

a:hover {
  color: #ff8c00;
  text-decoration: none;
}

/* Bootstrap Overwrites */

.form-control,
.custom-select {
  border: 1px solid #dee2e7;
  border-radius: 5px;
  height: 45px;
  font-size: 15px;
}

.form-control::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #8f8f8f;
  opacity: 1;
  /* Firefox */
}

.form-control:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: #8f8f8f;
}

.form-control::-ms-input-placeholder {
  /* Microsoft Edge */
  color: #8f8f8f;
}

.btn-sm {
  padding: 0.4rem !important;
  font-size: 13px !important;
}

.btn-link {
  /* font-weight: 400; */
  color: #ff9a00;
  text-decoration: none;
}

.btn-link:hover {
  /* font-weight: 400; */
  color: #ffb300;
  text-decoration: none;
}

.btn-primary {
  color: #fff;
  background-color: #ff9a00;
  border-color: #ff9a00;
  /* height: 45px; */
}

.btn-primary:focus,
.btn-primary:hover {
  color: #fff;
  background-color: #ff8c00;
  border-color: #ff8c00;
}

.btn-primary.disabled,
.btn-primary:disabled {
  color: #fff;
  background-color: #ff8c00;
  border-color: transparent;
  opacity: 0.6;
}

.btn-primary:not(:disabled):not(.disabled).active,
.btn-primary:not(:disabled):not(.disabled):active,
.show > .btn-primary.dropdown-toggle {
  color: #fff;
  background-color: #ff8c00;
  border-color: #ff8c00;
}

.btn-outline-primary {
  border-color: #ff8c00;
  color: #ff8c00;
  /* background-color: transparent !important; */
}

.btn-outline-primary:focus,
.btn-outline-primary:hover {
  background-color: #ff8c00;
  color: #ff9a00;
  border-color: #ff9a00;
  outline: none;
  box-shadow: none;
}

.btn-order-confirm {
  font-size: 13px;
  height: 40px;
}

.bg-light {
  background-color: #f9f9f9 !important;
}

.card {
  border: none;
  border-radius: 10px;
}

.card-footer {
  background-color: white;
}

/* checkbox round */
.checkbox-round {
  width: 1.3em;
  height: 1.3em;
  background-color: white;
  border-radius: 50%;
  vertical-align: middle;
  border: 1px solid #555;
  appearance: none;
  -webkit-appearance: none;
  outline: none;
  cursor: pointer;
  margin-right: 12px;
}

.checkbox-round:checked {
  background-color: #ff9a00;
}

/* checkbox square */
.checkbox-square {
  width: 1.3em;
  height: 1.3em;
  background-color: white;
  vertical-align: middle;
  border: 1px solid #555;
  appearance: none;
  -webkit-appearance: none;
  outline: none;
  cursor: pointer;
  margin-right: 12px;
}

.checkbox-square:checked {
  background-color: #ff9a00;
}

/* No data found  */
.no-data-holder {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: rgb(255 255 255);
  padding: 1.5rem;
  text-align: center;
  border-radius: 10px;
}

.no-data-holder img {
  width: 140px;
  margin: 10px 0;
}

.no-data-holder .icon {
  font-size: 2rem;
  color: #828282;
}

.no-data-msg {
  margin-top: 1rem;
  margin-bottom: 0.5rem;
  color: #828282;
  font-size: 1rem;
  font-weight: normal;
}

.link-icon {
  font-size: 20px;
}

.load-content-wrapper {
  display: flex;
  justify-content: center;
  align-content: center;
  padding: 2rem 1rem;
  margin-bottom: 1rem;
  color: #fff3e1;
  background-color: #ed8f00;
  border-radius: 10px;
}

.load-content-text {
  /* padding: 1rem; */
  min-height: 4rem;
}

.load-content-img {
  height: 30px;
}

/* simple - enter transition 300ms, exit 150ms */

.fade-appear,
.fade-enter {
  opacity: 0;
  z-index: 1;
}

.fade-appear-active,
.fade-enter.fade-enter-active {
  opacity: 1;
  transition: opacity 300ms linear 150ms;
}

.fade-exit {
  opacity: 1;
}

.fade-exit.fade-exit-active {
  opacity: 0;
  transition: opacity 150ms linear;
}

.mapDiv {
  width: 100%;
  height: 50%;
  position: absolute;
}

.header-title {
  font-weight: bold;
  color: #ffffff;
  /* text-transform: capitalize; */
  font-size: 18px;
  margin: 3px 0;
}

.header-logo img {
  height: 34px;
  width: auto;
}

.popover-box {
  display: block;
  position: absolute;
  padding: 1rem 1.2rem;
  border-radius: 0.6rem;
  background-color: #fff;
  bottom: 18px;
  left: -108px;
  z-index: 9999999;
  min-width: 240px;
  box-shadow: 0px 2px 7px rgba(0, 0, 0, 0.2);
}

.popover-box::after {
  content: "";
  position: absolute;
  bottom: -15px;
  left: 45%;
  border-style: solid;
  border-width: 15px 15px 0;
  border-color: #ffffff transparent;
  display: block;
  width: 0;
  z-index: 1;
}

.shop-name {
  font-size: 0.9rem;
  display: inline-block;
  font-weight: bold;
  color: #525252;
  margin: 0 0 1rem;
  line-height: normal;
}

.shop-adrs {
  font-size: 0.8rem;
  color: #777;
  margin-bottom: 15px;
}

.button-link {
  display: block;
  text-transform: uppercase;
  font-size: 0.8rem;
  font-weight: bold;
  color: #ff6700;
  padding: 0.5rem 1rem;
  border-radius: 0.3rem;
  text-align: center;
  background-color: #f9f9f9;
  text-decoration: none;
  cursor: pointer;
  transition: all 0.5s;
}

.button-link:hover {
  color: #2a4285;
}

/* nav header  */
.nav-header {
  position: fixed;
  width: 100%;
  height: 65px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  background-color: #ff9a00;
  color: #fff;
  z-index: 999;
}

.nav-header div:first-child {
  padding: 4px 0;
  font-size: 13px;
  cursor: pointer;
}

.nav-header .nav-header-icon {
  font-size: 24px;
  color: #fff;
  margin: 0 3px 0 0;
  overflow: visible;
}

.partial-header-logo {
  position: absolute;
  /* left: 45.5%; */
  left: 48%;
  top: 12px;
  width: 50px;
  height: auto;
}

.product-detail-modal-header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.bottom-fixed-btn {
  position: fixed;
  bottom: 1rem;
  /* margin: 0 20px 20px; */
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 10px 12px;
  background-color: #ff9a00;
  color: #fff;
  border: none;
  border-radius: 5px;
  z-index: 9999999999;
}

.map-img {
  width: 100%;
  height: auto;
  margin-bottom: 1.5rem;
}

/* .shop-channels {
  background: #ffffff;
  border: 1px solid #dedede;
  padding: 3px 9px;
  margin-bottom: 5px;
}

.shop-channels.active {
  border: 1px solid #ff6700;
  color: #ff6700;
  padding: 3px 9px;
  margin-bottom: 5px;
} */

/* Offcanvas Menu Css */

.offcanvas-title {
  /* display: flex;
  justify-content: center;
  align-items: center; */
  padding: 16px 20px;
  /* background: linear-gradient(153deg, #ff9a00 0%, #ff8c00 35%, #ffb300 100%); */
  background-color: #343434;
  color: #fff;
  font-size: 20px;
  border-bottom: 0.5px solid #dedede;
}

.home-offcanvas-title {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 16px 20px;
}

.close-img {
  width: 16px;
  height: auto;
}

.profile-img {
  width: 16px;
  height: auto;
  margin-right: 5px;
}

.offcanvas-menu_title {
  font-size: 13px;
  margin-left: 1.5rem;
  font-weight: bold;
  color: #ffffff;
  text-transform: uppercase;
  letter-spacing: 0.3rem;
}

.offcanvas-menu_link {
  display: block;
  padding: 15px 20px;
  color: #565353;
  font-size: 16px;
  margin-top: 6px;
}

.offcanvas-menu_link:focus,
.offcanvas-menu_link:hover {
  text-decoration: none;
  background-color: #fffaf6;
}

.offcanvas-menu_icon {
  color: #ff9a00;
  margin-top: -5px;
  margin-right: 15px;
  margin-left: 0;
  background-color: #fffaf6;
  padding: 5px 6px;
  border-radius: 10px;
}

.offcanvas-menu_icon .icon {
  font-size: 19px;
  margin-top: -4px;
}

.offcanvas-menu_logo {
  width: auto;
  height: 33px;
}

.bottom-line {
  position: fixed;
  bottom: 0;
  width: 100%;
  height: 100px;
}

.pagination {
  justify-content: center;
}

.page-link {
  color: #ff9a00;
  background-color: #fff;
  border: 1px solid #dee2e6;
  margin: 0 3px;
}

.page-item.active .page-link {
  z-index: 3;
  color: #fff;
  background-color: #ff6b00;
  border-color: #ff6b00;
}

.qty-input {
  width: 90px !important;
  height: 30px !important;
  border: none !important;
  z-index: -1;
  background: #f9f9f9;
  border-radius: 2rem !important;
  padding-right: 1rem !important;
  padding-left: 1rem !important;
  font-size: 15px !important;
  pointer-events: none;
}

.qty-input:focus,
.qty-input:hover {
  outline: none !important;
}

.qty-input-holder b {
  border-radius: 2rem !important;
  background: #fff !important;
  cursor: pointer !important;
}

.bottom-fixed-area {
  width: 100%;
  position: fixed;
  bottom: 0;
  padding: 10px;
  background-color: white;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.bottom-fixed-area .qty-input-holder b {
  background: #ff9a00 !important;
  color: #fff;
}

.block-label {
  font-weight: bold;
  color: #676767;
  font-size: 17px;
  margin-bottom: 3px;
}

/* Loading CSS */
.loading-ellipsis {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 20px;
}

.loading-ellipsis div {
  position: absolute;
  width: 13px;
  height: 13px;
  border-radius: 50%;
  background: #ff9a00;
  animation-timing-function: cubic-bezier(0, 1, 1, 0);
}

.loading-ellipsis div:nth-child(1) {
  left: 8px;
  animation: loading-ellipsis1 0.6s infinite;
}

.loading-ellipsis div:nth-child(2) {
  left: 8px;
  animation: loading-ellipsis2 0.6s infinite;
}

.loading-ellipsis div:nth-child(3) {
  left: 32px;
  animation: loading-ellipsis2 0.6s infinite;
}

.loading-ellipsis div:nth-child(4) {
  left: 56px;
  animation: loading-ellipsis3 0.6s infinite;
}

@keyframes loading-ellipsis1 {
  0% {
    transform: scale(0);
  }

  100% {
    transform: scale(1);
  }
}

@keyframes loading-ellipsis3 {
  0% {
    transform: scale(1);
  }

  100% {
    transform: scale(0);
  }
}

@keyframes loading-ellipsis2 {
  0% {
    transform: translate(0, 0);
  }

  100% {
    transform: translate(24px, 0);
  }
}

@media (min-width: 480px) {
  .fluid,
  .coupon-detail-grid {
    flex-direction: row;
  }

  .fluid__instructions,
  .coupon-detail-info {
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
  }

  .fluid__image-container,
  .coupon-detail_img {
    flex: 0 0 50%;
    margin: 0;
    z-index: 1;
  }
}

.content-section {
  padding: 5rem 0;
  min-height: 100vh;
  background-color: #f9f9f9;
}

div.closed-shop {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  background: rgba(0, 0, 0, 0.349);
  width: 100%;
  height: 100%;
  z-index: 10;
  /* top: 4rem; */
  left: 0;
  position: fixed;
}

.closed-warning-card {
  margin-top: 7.65rem;
  display: block;
  text-align: center;
  padding: 2rem;
  background-color: #ffffff;
  border-radius: 8px;
}

.closed-warning-card span {
  font-size: 14px;
}

.closed-warning-card .btn .icon {
  font-size: 1rem;
  margin-top: -4px;
}

.closed-icon {
  width: 80px;
  margin-bottom: 1rem;
}

/*======= Map Styling ============*/

.gmnoprint a,
.gmnoprint span,
.gmnoprint {
  display: none;
}

.gmnoprint div {
  background: none !important;
}

#GMapsID div div a div img {
  display: none;
}

.login-text {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.label-remember-term {
  color: #fff;
  font-size: 10px;
  text-transform: uppercase;
  font-weight: 300;
}

.filter-dropdown button {
  background: none;
  box-shadow: none;
  border: none;
  padding: 3px 10px;
}

.filter-dropdown button img {
  width: 20px;
  height: auto;
}

/* .btn-secondary {
  padding: 10px 30px;
  font-weight: bold;
  height: 45px;
} */

.filter-dropdown .btn-secondary:not(:disabled):not(.disabled).active:focus,
.filter-dropdown .btn-secondary:not(:disabled):not(.disabled):active:focus,
.filter-dropdown .show > .btn-secondary.dropdown-toggle:focus {
  box-shadow: none;
  background: none;
  border: none;
  background-color: transparent;
}

.filter-dropdown .btn-secondary:focus,
.filter-dropdown .btn-secondary:hover {
  box-shadow: none;
  background: none;
  border: none;
  background-color: transparent;
}

.filter-dropdown .dropdown-menu {
  position: absolute;
  left: -30px !important;
  padding: 3px;
  border: none;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

/* Loading Spinner CSS */
.loading {
  position: fixed;
  z-index: 999;
  height: 2em;
  width: 2em;
  overflow: visible;
  margin: auto;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}

.loading:before {
  content: "";
  display: block;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.3);
  opacity: 0.8;
}

.loading .letter-holder {
  padding: 16px;
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.loading .letter {
  float: left;
  font-size: 24px;
  color: white;
  font-weight: 600;
  font-family: sans-serif;
}

/* Transparent Overlay */

.loading .letter {
  animation-name: loadingF;
  animation-duration: 1.6s;
  animation-iteration-count: infinite;
  animation-direction: linear;
}

.loading .l-1 {
  animation-delay: 0.48s;
}

.loading .l-2 {
  animation-delay: 0.6s;
}

.loading .l-3 {
  animation-delay: 0.72s;
}

.loading .l-4 {
  animation-delay: 0.84s;
}

.loading .l-5 {
  animation-delay: 0.96s;
}

.loading .l-6 {
  animation-delay: 1.08s;
}

.loading .l-7 {
  animation-delay: 1.2s;
}

.loading .l-8 {
  animation-delay: 1.32s;
}

.loading .l-9 {
  animation-delay: 1.44s;
}

.loading .l-10 {
  animation-delay: 1.56s;
}

/* Animation */

@keyframes loadingF {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

.alert-icon {
  color: #676767;
  background: #d0d0d0;
  width: 45px;
  height: 45px;
  border-radius: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 10px 0;
}

.alert-icon.success {
  color: #0c940c;
  background: #b6ffb6;
}

.alert-icon.warning {
  color: #f79605;
  background: #f7dfa9;
}

.alert-icon.danger {
  color: #f70525;
  background: #ffb8c1;
}

.alert-icon .icon {
  font-size: 1.5rem;
}

.badge {
  display: inline-block;
  padding: 0.45em 0.8em;
  font-size: 75%;
  font-weight: bold;
  line-height: 1.2;
}

/* Modal CSS */

.modal-custom .modal-content {
  height: auto;
}

.modal-custom .modal-content .modal-body {
  /* display: flex; */
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 1rem;
  /* text-align: center; */
}

/* .modal-dialog.modal-custom.product-list-modal {
  width: 500px;
} */

.modal-custom.product-list-modal .modal-body {
  padding-right: 0.25rem;
  padding-left: 0.25rem;
}

.modal-custom.product-list-modal .product-list-item {
  border: 1px solid #dedede;
}

.modal .modal-dialog {
  position: relative;
  min-width: 320px;
  margin: auto;
  pointer-events: none;
}

.modal-header {
  padding: 5px 20px;
  border-bottom: none;
}

.modal-icon {
  width: 35px;
  height: 35px;
  margin-bottom: 0.8rem;
}

.modal-text {
  color: #676767;
  font-weight: normal;
  font-size: 14px;
}

.modal-content {
  border-radius: 10px;
}

.modal-message {
  color: #676767;
  font-weight: 400;
  font-size: 14px;
  text-align: center;
  margin-top: 10px;
}

.close {
  position: absolute;
  right: 6px;
  top: 5px;
  width: 18px;
  height: 18px;
  opacity: 0.3;
  cursor: pointer;
}

.close:hover {
  opacity: 1;
}

.modal-header .close {
  padding: 0;
  margin: 0;
}

.close:before {
  transform: rotate(45deg);
}

.close:after {
  transform: rotate(-45deg);
}

.modal-close-icon {
  position: absolute;
  right: 10px;
  top: 5px;
}

.btn.disabled,
.btn:disabled {
  opacity: 0.35;
  cursor: not-allowed;
}

.shop-info-header {
  display: flex;
  align-items: center;
  padding: 12px;
  border-radius: 6px;
  background-color: #ffffff;
  margin-bottom: 1rem;
  box-shadow: 2px 2px 3px rgb(0 0 0 / 3%);
}

.shop-info-header img {
  width: 45px;
  height: auto;
  padding: 3px;
  background-color: #f7f7f7;
  border-radius: 6px;
  margin-right: 1rem;
}

.shop-info-header .name {
  color: #777777;
  font-size: 16px;
  margin: 0;
}

#loading {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
  opacity: 0.7;
  background-color: #fff;
  z-index: 999999;
}

#loading-image {
  width: 80px;
  height: auto;
  z-index: 100;
  margin-bottom: 1rem;
}

#small-loading {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 10vh;
  opacity: 0.7;
  z-index: 999999;
}

#small-loading-image {
  width: 50px;
  height: auto;
  z-index: 100;
  margin-bottom: 1rem;
}

.content-loading {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 0;
  height: 100vh;
  z-index: 999999;
  width: 100%;
}

.content-loading img {
  width: 40px;
  height: auto;
  z-index: 100;
  margin-bottom: 1rem;
}

/* loading text dots */
.loading-text {
  font-weight: bold;
  font-size: 14px;
  color: #bbbbbb;
}

.loading-text:after {
  overflow: hidden;
  display: inline-block;
  vertical-align: bottom;
  -webkit-animation: ellipsis steps(4, end) 900ms infinite;
  animation: ellipsis steps(4, end) 900ms infinite;
  content: "\2026";
  /* ascii code for the ellipsis character */
  width: 0px;
}

@keyframes ellipsis {
  to {
    width: 1.25em;
  }
}

@-webkit-keyframes ellipsis {
  to {
    width: 1.25em;
  }
}

/* New CSS */

/* Header CSS */

.toggle-icon {
  width: 25px;
  height: auto;
}

.home__content-info {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  /* height: 90vh; */
  padding: 0 2rem;
  /* margin-bottom: 2rem; */
}

.promotion_banner {
  margin-top: 5.4rem;
  margin-bottom: 3rem;
}

.home__content-info .btn {
  position: relative;
  font-size: 1.1rem;
  letter-spacing: 0.03rem;
  height: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-transform: uppercase;
  transition: all 0.3s;
}

.home_btn-icon {
  position: absolute;
  top: 15px;
  left: 1rem;
  font-size: 1.8rem;
}

.rating {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.rating .icon {
  color: #676767;
  font-size: 22px;
}

.rating span {
  color: #676767;
  font-size: 22px;
  font-weight: bold;
}

/* Pick Up CSS */

.pick-up__info,
.dine-in__info {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.block-sub__label {
  color: #676767;
  font-size: 20px;
  font-weight: 700;
  margin-top: 10px;
}

.store-icon-holder {
  border: none;
  margin-right: 16px;
}

.store-icon {
  font-size: 25px;
  color: #ff9a00;
}

.store-icon-img {
  width: 55px;
  border-radius: 8px;
  padding: 5px;
  background-color: #fff;
  box-shadow: 0 2px 2px rgb(0 0 0 / 25%);
}

.homepage-shop-name {
  font-size: 16px;
  color: #4c4c4c;
  font-weight: bold;
  margin: 0;
}

.pickup-time-icon {
  font-size: 21px;
  color: #ff6b00;
}

.text-yellow {
  color: #ff6b00 !important;
}

.order-detail-content {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: wrap;
}

.order-detail-content span:first-child {
  color: #111111;
}

.order-countdown-wrapper {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0;
  margin-bottom: 10px;
}

.order-countdown-wrapper-waiting {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0;
  margin-bottom: 10px;
}

.order-countdown-wrapper .cd-span {
  padding: 0 5px;
  color: #ff6700;
  font-size: 21px;
  font-weight: bold;
}

.order-countdown-wrapper_waiting .cd-span {
  padding: 0 5px;
  color: #fff;
  font-size: 21px;
  font-weight: bold;
}

.order-countdown-wrapper .cd-span.label {
  font-size: 12px;
  text-transform: capitalize;
  padding: 4px 6px 0 0 !important;
  font-weight: normal !important;
}

.order-countdown-wrapper-waiting .cd-span.label {
  font-size: 12px;
  text-transform: capitalize;
  padding: 4px 6px 0 0 !important;
  font-weight: normal !important;
}

.change-password-form .form-group .icon {
  color: #888888;
  position: absolute;
  right: 1rem;
  top: 2.7rem;
  cursor: pointer;
}

.warning-icon {
  width: 50px !important;
  height: 50px !important;
}

.shop-on-map {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0.5rem 1rem;
}

.shop-on-map .image {
  width: 80px;
  height: 80px;
  padding: 6px;
  border-radius: 50%;
  background-color: #ffffff;
  box-shadow: 0 2px 8px rgb(0 0 0 / 16%);
}

.shop-on-map .image img {
  width: 100%;
  height: auto;
  border-radius: 50%;
}

.shop-on-map .name {
  font-weight: bold;
  font-size: 18px;
  color: #676767;
  margin: 15px 0;
  text-align: center;
}

.no-internet-content {
  min-height: 90vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 2rem 5rem;
  text-align: center;
}

.no-internet-icon {
  width: 80px;
  height: auto;
  margin-bottom: 2rem;
}

.my-location-icon {
  color: #ff9a00 !important;
  font-size: 26px !important;
  margin-right: 10px !important;
}

/* Media Queries */

@media (min-width: 576px) {
  .modal-dialog {
    max-width: 700px;
  }
}

@media only screen and (max-width: 767.98px) {
  .bottom-fixed-btn {
    width: 92% !important;
  }

  .modal-dialog.modal-custom.product-list-modal {
    width: 360px;
  }
}

@media (min-width: 768px) {
  .bottom-fixed-btn {
    width: 92%;
  }

  .navbar-nav {
    flex-direction: row;
  }
}

@media only screen and (max-width: 992px) {
  .bottom-fixed-btn {
    width: 90%;
  }
}

@media (min-width: 1200px) {
  .bottom-fixed-btn {
    width: 82% !important;
  }

  .modal-dialog.modal-custom.product-list-modal {
    width: 500px;
  }
}

.payment-qr-code {
  width: auto;
  height: 300px;
}

.payment-image {
  width: auto;
  height: 75px;
}

.order-type.pos {
  border-color: #039b47;
  color: #039b47;
}

.order-type.pick-up {
  border-color: #95049c;
  color: #95049c;
}

.order-type.delivery {
  border-color: #e53a29;
  color: #e53a29;
}

.order-type.dine-in {
  border-color: #0051ff;
  color: #0051ff;
}

.order-item_underline {
  border-bottom: 2px dashed #ebebeb;
  margin: 1rem 0;
}

/* .notification overwrites  */

.notification-container {
  box-sizing: border-box;
  position: fixed;
  top: auto;
  bottom: 1rem;
  left: 0;
  z-index: 999999;
  width: 320px;
  padding: 0px 15px;
  max-height: calc(100% - 30px);
  overflow-x: hidden;
  overflow-y: auto;
}

.notification {
  box-sizing: border-box;
  padding: 15px 15px 15px 58px;
  border-radius: 8px;
  color: #fff;
  background-color: #ccc;
  box-shadow: 0 0 12px rgba(0, 0, 0, 0.18);
  cursor: pointer;
  font-size: 0.75em;
  line-height: 1.2em;
  position: relative;
  opacity: 0.9;
  margin-top: 15px;
}

.notification .title {
  font-size: 1.2em;
  line-height: 1.2em;
  font-weight: bold;
  margin: 0 0 5px 0;
}

.notification:hover,
.notification:focus {
  opacity: 1;
}

.notification-enter {
  visibility: hidden;
  transform: translate3d(100%, 0, 0);
}

.notification-enter.notification-enter-active {
  visibility: visible;
  transform: translate3d(0, 0, 0);
  transition: all 0.4s;
}

.notification-leave {
  visibility: visible;
  transform: translate3d(0, 0, 0);
}

.notification-leave.notification-leave-active {
  visibility: hidden;
  transform: translate3d(100%, 0, 0);
  transition: all 0.4s;
}

.notification:before {
  position: absolute;
  top: 50%;
  left: 15px;
  margin-top: -14px;
  display: block;
  font-family: "Notification";
  width: 24px;
  height: 24px;
  font-size: 24px;
  text-align: center;
  line-height: 24px;
}

.notification-info {
  background-color: #ddf7ff;
  color: #2f96b4;
}

.notification-info:before {
  content: "";
}

.notification-success {
  background-color: #e9ffe9;
  color: #51a351;
}

.notification-success:before {
  content: url("./assets/image//logo_small.png");
}

.notification-warning {
  background-color: #fff3e2;
  color: #f89406;
}

.notification-warning:before {
  content: "";
}

.notification-error {
  background-color: #ffe7e6;
  color: #bd362f;
}

.notification-error:before {
  content: "";
}

.pin {
  width: 25px;
  height: 25px;
  border-radius: 50% 50% 50% 0;
  /* background: #db0202; */
  background-color: #ff6500;
  position: absolute;
  -webkit-transform: rotate(-45deg);
  -moz-transform: rotate(-45deg);
  -o-transform: rotate(-45deg);
  -ms-transform: rotate(-45deg);
  transform: rotate(-45deg);
  left: 50%;
  top: 50%;
  margin: -20px 0 0 -20px;
  -webkit-animation-name: bounce;
  -moz-animation-name: bounce;
  -o-animation-name: bounce;
  -ms-animation-name: bounce;
  animation-name: bounce;
  -webkit-animation-fill-mode: both;
  -moz-animation-fill-mode: both;
  -o-animation-fill-mode: both;
  -ms-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-animation-duration: 1s;
  -moz-animation-duration: 1s;
  -o-animation-duration: 1s;
  -ms-animation-duration: 1s;
  animation-duration: 1s;
}

.pin:after {
  content: "";
  width: 9px;
  height: 9px;
  margin: 8px 0 0 8px;
  background: #ffffff;
  position: absolute;
  border-radius: 50%;
}

.pulse {
  background: #db0202;
  border-radius: 50%;
  height: 9px;
  width: 9px;
  position: absolute;
  left: 50%;
  top: 50%;
  margin: 11px 0px 0px -12px;
  -webkit-transform: rotateX(55deg);
  -moz-transform: rotateX(55deg);
  -o-transform: rotateX(55deg);
  -ms-transform: rotateX(55deg);
  transform: rotateX(55deg);
  z-index: -2;
}

.pulse:after {
  content: "";
  border-radius: 50%;
  height: 40px;
  width: 40px;
  position: absolute;
  margin: -16px 0 0 -16px;
  -webkit-animation: pulsate 1s ease-out;
  -moz-animation: pulsate 1s ease-out;
  -o-animation: pulsate 1s ease-out;
  -ms-animation: pulsate 1s ease-out;
  animation: pulsate 1s ease-out;
  -webkit-animation-iteration-count: infinite;
  -moz-animation-iteration-count: infinite;
  -o-animation-iteration-count: infinite;
  -ms-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
  opacity: 0;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
  filter: alpha(opacity=0);
  -webkit-box-shadow: 0 0 1px 2px #db0202;
  box-shadow: 0 0 1px 2px #db0202;
  -webkit-animation-delay: 1.1s;
  -moz-animation-delay: 1.1s;
  -o-animation-delay: 1.1s;
  -ms-animation-delay: 1.1s;
  animation-delay: 1.1s;
}

.otp-input-root input {
  width: 38px !important;
  height: 38px !important;
}

.badge-primary {
  color: #ff9a00;
  background-color: #ffeee0;
}

.badge-blue {
  color: #217cdc;
  background-color: #d6ebff;
}

.badge-green {
  color: #008631;
  background-color: #d9fae5;
}

.badge-danger {
  color: #ff5252;
  background-color: #fcdede;
}

.badge-info {
  color: #09bcdb;
  background-color: #d4f9ff;
}

.badge-purple {
  color: #b10ccf;
  background-color: #eccced;
}

/* qr scanner styles  */
.qr-scanner {
  position: relative;
}

.scanner-frame {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 999999;
  background-color: rgba(0, 0, 0, 0.333);
}

.scanner-frame .scanner-border {
  display: block;
  width: 250px;
  height: 250px;
  border-radius: 12px;
  border: 2px dotted #f3fff3;
}

.scanner-frame .qr-page-back-link {
  position: absolute;
  top: 15px;
  left: 20px;
  color: #ffffff;
  font-size: 14px;
}

@import url("https://fonts.googleapis.com/css2?family=IBM+Plex+Sans+Thai:wght@400;500;600;700&family=Roboto:wght@300;400;500;700;900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=IBM+Plex+Sans+Thai:wght@400;500;600;700&family=Lato:wght@300;400;700;900&family=Roboto:wght@300;400;500;700;900&display=swap");

:root {
  /* color */
  --primary: #ff9a00;
  --primaryDark: #ffb300;
  --primaryAccent: #ffd5a1;
  --secondary: #8e8e8e;
  --white: #ffffff;
  --black: #3a3938;
  --gray: #403d44;
  --grayLight: #707070;
  --medium: #7d8a8b;
  --mediumLight: #e2e5ea;
  --light: #f9f9f9;
  --danger: #ff5252;
  --success: #2cc75f;
  --green: "#008631";
  --greenAccent: "#d9fae5";
  --warning: #fb0;
  --blue: #217cdc;
  --rgba: #000000aa;

  /* font family */
  /* --body-font: '';
  --title-font: '';
  --section-font:''; */
}

/* Overwrite CSS */

a {
  color: var(--primary);
  background-color: transparent;
  text-decoration: none;
}

a:hover {
  color: var(--primaryDark);
  text-decoration: none;
}

ul {
  list-style: none;
  padding: 0;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "IBM Plex Sans Thai", sans-serif;
}

.form-group {
  margin-bottom: 1rem;
}

.form-label {
  color: var(--gray);
  font-size: 16px;
  font-weight: 700;
  margin-bottom: 0.25rem;
}

.btn {
  padding: 0.7rem 2rem;
  font-size: 15px;
  font-weight: bold;
  border-radius: 5px;
  /* box-shadow: 0 3px 12px 0 rgba(255, 161, 9, 0.589); */
  transition: ease-in 150ms;
  /* height: 50px; */
}

.product-detail-modal .btn {
  padding: 0.5rem 1rem;
  height: 50px;
}

.btn-primary {
  color: var(--white);
  background-color: var(--primary);
  border: none;
}

.btn-primary:focus,
.btn-primary:hover {
  color: var(--white);
  background-color: var(--primaryDark);
  box-shadow: none;
}

.btn-outline-primary {
  color: var(--primary);
  border-color: var(--primary);
}

.btn-outline-primary:focus,
.btn-outline-primary:hover {
  color: var(--white);
  background-color: var(--primary) !important;
  border-color: var(--primary);
}

.btn-white {
  color: var(--primary);
  background-color: var(--white);
  border: none;
  box-shadow: none;
}

.btn-white:hover {
  color: var(--primary);
}

.btn-outline-white {
  color: var(--white);
  border-color: var(--white);
  box-shadow: none;
}

.btn-outline-white:hover,
.btn-outline-white:focus {
  color: var(--primary);
  background-color: var(--white);
  border-color: var(--white);
}

.site-nav .nav-item .nav-link {
  color: var(--medium);
  font-size: 14px;
  padding: 0 0 10px 0;
}

.site-nav .nav-item .nav-link.btn-login {
  background-color: #f6a23a;
  color: var(--white);
  padding: 0.8rem 2rem;
  font-size: 17px;
  font-weight: bold;
  border-radius: 2rem;
  box-shadow: 0 3px 12px 0 rgba(255, 161, 9, 0.589);
  width: 140px;
}

.site-nav .nav-item.active .nav-link {
  color: var(--primary);
  padding: 8px 10px 20px 0;
}

.top-bg-graphic {
  width: 100%;
  background-image: url("./assets/image//top-bg-graphic.png");
  -webkit-background-size: cover;
  -moz-background-size: cover;
  background-size: cover;
  -o-background-size: cover;
  /* height: 100vh; */
  padding: 0.5rem 0;
  position: relative;
}

.hand-img-one {
  position: absolute;
  top: -75px;
  left: -86px;
  width: 165px;
  height: auto;
}

.hand-img-two {
  position: absolute;
  top: -135px;
  right: 0;
  width: 100px;
  height: auto;
}

/* Header CSS */
.header {
  display: flex;
  align-items: center;
}

.header .header-nav {
  padding: 0.5rem 2rem;
}

.header .header-nav .navbar-brand {
  margin-right: 7px;
}

.header-logo {
  width: 160px;
  height: auto;
}

/* .logo-title {
  color: var(--medium);
  font-size: 22px;
  font-weight: bold;
} */

.header-nav .nav-item .nav-link {
  color: var(--gray);
  padding: 12px 20px;
  transition: 0.3s;
  font-size: 17px;
  font-weight: 700;
  text-transform: capitalize;
}

.header-nav .nav-item.login .nav-link {
  color: var(--white);
  padding: 7px 20px;
}

.header-nav .nav-link.btn-login {
  background-color: #f6a23a;
  color: var(--white);
  padding: 0.8rem 2rem;
  font-size: 17px;
  font-weight: bold;
  border-radius: 2rem;
  box-shadow: 0 3px 12px 0 rgba(255, 161, 9, 0.589);
  cursor: pointer;
}

.header-nav .nav-link.btn-login:hover {
  box-shadow: none;
}

.header .profile-img {
  width: 16px;
  height: auto;
  margin-right: 5px;
}

.offcanvas-menu_link.btn-login {
  background-color: #f6a23a;
  color: var(--white);
  padding: 0.8rem 2rem;
  font-size: 17px;
  font-weight: bold;
  border-radius: 2rem;
  box-shadow: 0 3px 12px 0 rgb(255 161 9 / 59%);
  width: 140px;
  margin: 15px 20px;
}

.underline {
  border-bottom: 1px solid var(--mediumLight);
  margin: 9px 0 20px 0;
}

.mobile-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.mobile-header .mobile-header-logo {
  width: 120px;
  height: auto;
}

.mobile-header .menu-img {
  width: 18px;
  height: auto;
  /* margin-right: 1rem; */
}

/* Offcanvas CSS */

.offcanvas-header .close-img {
  width: 16px;
  height: auto;
}

.offcanvas-end {
  width: 85%;
  border-left: 1px solid rgba(0, 0, 0, 0.2);
}

.offcanvas-header .logo {
  width: 40px;
  height: auto;
}

.offcanvas-body .nav-item.underline {
  border-bottom: 2px solid var(--mediumLight);
  margin: 7px 0 20px 0;
}

/* Banner CSS */
.banner-section {
  padding-top: 1rem;
}

.banner-section h1 {
  color: var(--gray);
  font-size: 41px;
  font-weight: bold;
  margin-bottom: 10px;
}

.banner-section h1 span {
  color: var(--primary);
}

.banner-section p {
  color: var(--gray);
  font-size: 22px;
  font-weight: 500;
  margin-bottom: 20px;
}

.banner-section .bag-img {
  width: 15px;
  height: auto;
  margin-top: -4px;
  margin-right: 5px;
}

.banner-section .payment-web-img {
  width: auto;
  height: 260px;
  position: absolute;
  top: 140px;
  left: 130px;
}

.banner-section #shop-img {
  width: 750px;
  height: auto;
  margin-top: 2rem;
  margin-bottom: 1rem;
}

.banner-section .item-web-img {
  width: 265px;
  height: auto;
  position: absolute;
  top: 295px;
  right: 125px;
}

.banner-section .item-info {
  width: 295px;
  height: auto;
  position: absolute;
  top: 470px;
  right: 35px;
}

.banner-section .item-info {
  display: flex;
  background-color: white;
  border-radius: 20px;
  box-shadow: 3px 6px 16px 4px rgb(0 0 0 / 10%);
}

.banner-section .item-info .item-img_holder {
  margin-right: 7px;
}

.banner-section .item-info .item-img {
  width: 90px;
  height: auto;
  border-radius: 20px;
}

.banner-section .item-info .item-name_holder {
  padding: 5px 12px;
}

.banner-section .item-info .item-name {
  color: var(--gray);
  font-weight: 500;
  box-sizing: border-box;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow-wrap: break-word;
  -webkit-line-clamp: 1;
  overflow: hidden;
  width: 99%;
}

.banner-section .item-info .description {
  color: var(--medium);
  font-size: 14px;
  margin-bottom: 12px;
  box-sizing: border-box;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow-wrap: break-word;
  -webkit-line-clamp: 2;
  overflow: hidden;
  width: 99%;
}

.banner-section .item-info .item-price {
  color: var(--white);
  font-size: 16px;
  font-weight: 700;
  background-color: var(--primary);
  border-radius: 30px;
  padding: 2px 14px;
}

.mobile-payment-img-one {
  width: 320px;
  height: auto;
}

.mobile-payment-img-two {
  width: 245px;
  height: auto;
  margin-top: 2rem;
}

.mobile-item-img-one {
  width: 100%;
  height: auto;
  margin-top: 2rem;
}

/* Slide Section CSS */
.slide-wrapper {
  -webkit-background-size: cover;
  -moz-background-size: cover;
  background-size: cover;
  -o-background-size: cover;
  background-position: fixed;
  padding: 7rem 0 4rem 0;
  height: 600px;
}

.slide-wrapper .title {
  color: var(--white);
  font-size: 40px;
  font-weight: bold;
}

.slide-wrapper .web-small-text {
  color: var(--white);
  font-size: 17px;
  font-weight: 500;
}

.slide-wrapper .button-group {
  display: flex;
  flex-direction: column;
}

.slide-wrapper .button-group .btn {
  display: flex;
  justify-content: center;
  align-items: center;
}

.slide-wrapper .button-group img {
  width: 20px !important;
  height: auto;
  margin-top: 3px;
  margin-right: 7px;
}

.slide-wrapper .slide-img-one {
  width: 100%;
  height: auto;
}

.slide-wrapper .slide-img-two,
.slide-wrapper .slide-img-three {
  width: 100%;
  height: auto;
}

.slide-top-wave-img {
  width: 100%;
  margin-bottom: -4rem;
}
@media (min-width: 990px) {
  .slide-top-wave-img {
    width: 100%;
    margin-bottom: -5rem;
  }
}

.slide-bottom-wave-img {
  width: 100%;
  margin-top: -1.25rem;
}

.slide-wrapper .owl-theme .owl-dots {
  /* position: absolute; */
  left: 73px;
  bottom: 35px;
}

.slide-wrapper .owl-theme .owl-dot span {
  width: 10px !important;
  height: 7px !important;
}

.slide-wrapper .owl-theme .owl-dot.active span {
  width: 22px !important;
  height: 7px !important;
  background-color: var(--white) !important;
}

.slide-wrapper .owl-carousel .owl-nav button.owl-prev,
.slide-wrapper .owl-carousel .owl-nav button.owl-next {
  display: none;
}

/* .item-slide-wrapper .owl-theme .owl-dots,
.owl-theme .owl-nav {
  text-align: left;
  -webkit-tap-highlight-color: transparent;
}

.item-slide-wrapper .owl-theme .owl-dot span {
  width: 10px !important;
  height: 7px !important;
  background-color: var(--primaryAccent) !important;
}

.item-slide-wrapper .owl-theme .owl-dot.active span {
  width: 22px !important;
  height: 7px !important;
  background-color: var(--primary) !important;
}

.item-slide-wrapper.web-item-img {
  margin-top: 8rem;
}

.item-slide-wrapper.web-item-img .owl-theme .owl-dots,
.owl-theme .owl-nav {
  position: absolute;
  right: 244px;
  top: 335px;
} */

/* Food CSS for slide section */
.food-img_holder {
  position: relative;
}

.food-img-one {
  position: absolute;
  width: 120px;
  height: auto;
  top: 37px;
  left: 25px;
}

.food-img-two {
  position: absolute;
  width: 120px;
  height: auto;
  top: 28px;
  right: 25px;
}

.food-img-three {
  position: absolute;
  width: 120px;
  height: auto;
  top: 625px;
  left: 40px;
}

/* Service CSS */
.service-section h4 {
  color: var(--gray);
  font-size: 35px;
  font-weight: bold;
  text-align: center;
  margin-bottom: 1.5rem;
}

.service-section h4 span {
  color: var(--primary);
}

.service-info {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 1rem;
  text-align: center;
}

.service-info .service-img_wrap {
  height: 295px;
}

.service-info .service-img-one,
.service-info
  .service-img-two
  .service-info
  .service-img-three
  .service-info
  .service-img-four {
  width: 100%;
  height: auto;
  margin-bottom: 1rem;
}

.service-info .service-name {
  color: var(--gray);
  font-size: 18px;
  font-weight: 500;
}

/* Why Use Us CSS */
.why-use-section {
  background-image: url("./assets/image//rectangle-color.png");
  -webkit-background-size: cover;
  -moz-background-size: cover;
  background-size: cover;
  -o-background-size: cover;
  padding: 1.5rem 0 0.5rem 0;
  margin-top: 1.5rem;
}

.why-use-section h4 {
  color: var(--white);
  font-size: 35px;
  font-weight: bold;
  text-align: center;
  margin: 2rem 0 2rem 0;
}

.wave-img,
.wave-white-bg-img {
  width: 100%;
  height: auto;
}

.wave-white-bg-img {
  margin-bottom: -7rem;
}

.why-use-section .card {
  background-color: var(--white);
  border-radius: 15px;
  margin-bottom: 10px;
}

.why-use-section .img-wrapper {
  height: auto;
  padding: 1rem 1rem 0;
}

.why-use-section img {
  width: auto;
  height: 222px;
}

.why-use-section .name-holder {
  padding: 0 1rem 1rem 1rem;
}

.why-use-section .name {
  color: var(--gray);
  font-size: 22px;
  font-weight: bold;
}

.service-section .name {
  color: var(--gray);
  font-size: 21px;
  margin-top: 10px;
  margin-bottom: 10px;
  font-weight: normal;
}

.service-section .new-img {
  width: auto;
  height: 235px;
  margin-bottom: 1rem;
}

.service-section p,
.why-use-section p {
  color: var(--gray);
  font-size: 17px;
  font-weight: 500;
  margin-bottom: 0;
}

.why-use-section .circle-one {
  width: 20px;
  height: 20px;
  background-color: #f6a23a;
  border-radius: 50%;
  margin-left: -0.75rem;
}

.why-use-section .circle-two {
  width: 20px;
  height: 20px;
  background-color: #f6a23a;
  border-radius: 50%;
  margin-right: -0.75rem;
}

.why-use-section .underline {
  width: 100%;
  border-bottom: 2px dashed var(--mediumLight);
  line-height: 0.1em;
}

/* Contact Us CSS */

.header.auth-wrapper {
  background-color: var(--white);
  height: 80px;
  box-shadow: 0 3px 2px rgb(0 0 0 / 0.05);
}

.auth-page {
  background-color: var(--light);
  background-image: url("./assets/image//bg-graphic.png");
  -webkit-background-size: cover;
  -moz-background-size: cover;
  background-size: cover;
  -o-background-size: cover;
  box-shadow: 2px 3px 2px rgb(0 0 0 / 0.05);
  padding: 3rem 0;
}

.auth-card {
  background-color: var(--white);
  padding: 1rem 1.5rem;
  border-radius: 15px;
  position: relative;
}

.auth-card h4 {
  color: var(--gray);
  font-size: 28px;
  font-weight: bold;
  margin-bottom: 3px;
}

.auth-card .small-text {
  color: var(--medium);
  font-size: 18px;
  font-weight: 700;
  margin-bottom: 15px;
}

.auth-page .form-control {
  height: 45px;
}

.auth-page .btn-auth {
  background-color: var(--primary);
  color: var(--white);
  border: none;
  padding: 0.8rem 1.75rem;
  font-size: 17px;
  font-weight: bold;
  border-radius: 2rem;
  box-shadow: 0 3px 12px 0 rgba(255, 161, 9, 0.589);
}

.auth-page .btn-auth:focus,
.auth-page .btn-auth:hover {
  box-shadow: none;
}

.wave-bg-gray-img {
  width: 100%;
  height: auto;
  margin-top: -0.75rem;
}

.map-img {
  width: 100%;
  height: auto;
}

.auth-card.contact-us {
  margin-top: -10rem;
}

.auth-page .form-control.custom-textarea-input {
  height: 100px;
}

.auth-page .main-title {
  color: var(--black);
  font-size: 25px;
  font-weight: bold;
  margin-bottom: 1rem;
  text-align: center;
}

.auth-page .sub-title {
  color: var(--black);
  font-size: 20px;
  font-weight: bold;
  margin-bottom: 0.5rem;
}

.auth-page .location-name {
  color: var(--medium);
  font-size: 16px;
  font-weight: 700;
  margin-left: 10px;
}

.auth-page .phone-number {
  color: var(--primary);
  font-size: 16px;
  font-weight: 700;
  margin-top: 3px;
  margin-left: 10px;
}

.location-img,
.phone-img {
  width: 30px;
  height: auto;
}

/* Help Center CSS */

.accordion-item:first-of-type .accordion-button {
  border-top-left-radius: 0 !important;
  border-top-right-radius: 0 !important;
}

.accordion-header .accordion-button {
  color: var(--gray);
  font-size: 16px;
  font-weight: bold;
}

.accordion-button {
  padding: 0.75rem 0 !important;
}

.accordion-body {
  padding: 0 0 0.5rem !important;
}

.accordion-button:not(.collapsed) {
  color: var(--primary);
  background-color: transparent !important;
  box-shadow: none !important;
}

.accordion-button:focus {
  border-color: none !important;
  box-shadow: none !important;
}

.accordion-header .accordion-button.collapsed {
  color: var(--gray);
  font-size: 15px;
  font-weight: bold;
}

.accordion-body p {
  color: var(--gray);
  font-size: 14px;
  font-weight: normal;
  margin-bottom: 0;
}

.accordion-item {
  border: 0 !important;
}

.accordion-flush .underline {
  border-bottom: 2px solid var(--mediumLight) !important;
  margin-top: 0.25rem;
}

.accordion-button::after {
  background-image: url("./assets/image//add_circle.png") !important;
}

.accordion-button:not(.collapsed)::after {
  background-image: url("./assets/image//remove_circle.png") !important;
  /* transform: rotate(-180deg); */
}

/* Footer CSS */
.footer {
  background-image: url("./assets/image//bg-graphic.png");
  -webkit-background-size: cover;
  -moz-background-size: cover;
  background-size: cover;
  -o-background-size: cover;
  padding: 2.5rem 0;
}

.footer h4 {
  color: var(--black);
  font-size: 18px;
  font-weight: 700;
  margin: 1rem 0;
}

.footer .social-wrapper {
  margin-bottom: 1rem;
}

.footer .social-img {
  width: 35px;
  height: auto;
}

.footer .main-title {
  color: var(--black);
  font-size: 22px;
  font-weight: bold;
  margin-bottom: 15px;
}

.footer .text {
  color: var(--medium);
  font-size: 16px;
  margin-bottom: 15px;
}

.footer p {
  color: var(--black);
  font-size: 16px;
  font-weight: 500;
}

.footer .mail-img {
  width: 17px;
  height: auto;
  margin-right: 5px;
}

.footer .arrow-right-img {
  margin-right: -10px;
}

.copy-right {
  color: var(--black);
  font-weight: bold;
  font-size: 16px;
  margin-top: 2rem;
  font-family: "Lato", sans-serif;
}

.footer .btn-primary {
  width: 250px;
}

/* nearby shop on homepage  */
/* .nearby-shop-wrapper {
  background-color: var(--white);
  border-radius: 20px;
  box-shadow: 3px 6px 16px 4px rgb(0 0 0 / 10%);
  width: 100%;
  min-height: 200px;
  padding: 1.5rem
} */

.nearby-shop-wrapper .owl-carousel .owl-nav button.owl-prev {
  width: 20px;
  height: 30px;
  position: absolute;
  top: 30%;
  margin-left: -10px;
  display: block !important;
  border: 1px solid var(--mediumLight);
  border-radius: 3px;
  background: var(--white);
  color: var(--primary);
  font-weight: bold;
}

.nearby-shop-wrapper .owl-carousel .owl-nav button.owl-next {
  width: 20px;
  height: 30px;
  position: absolute;
  top: 30%;
  right: -5px;
  display: block !important;
  border: 1px solid var(--mediumLight);
  border-radius: 3px;
  background: var(--white);
  color: var(--primary);
  font-weight: bold;
}

.nearby-shop-wrapper .owl-carousel .owl-nav button.owl-prev:hover,
.nearby-shop-wrapper .owl-carousel .owl-nav button.owl-next:hover {
  background-color: var(--primary);
  color: white;
}

.nearby-shop-wrapper .owl-prev span,
.nearby-shop-wrapper .owl-next span {
  font-size: 30px;
  line-height: 0.95;
}

.nearby-shop-wrapper .title {
  text-align: left;
  font-size: 21px;
  color: var(--gray);
  font-weight: bold;
  margin-bottom: 1rem;
}

.nearby-shop-wrapper .shop-list-wrap {
  margin-bottom: 1rem;
}

.nearby-shop-wrapper .shop-list-wrap .owl-carousel .item {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 15px;
  border: 0.8px solid var(--mediumLight);
  border-radius: 10px;
  margin-right: 10px;
  background-color: var(--white);
  cursor: pointer;
}

.nearby-shop-wrapper .shop-list-wrap .owl-carousel .item .item-name {
  color: var(--gray);
  font-weight: 500;
  box-sizing: border-box;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow-wrap: break-word;
  -webkit-line-clamp: 2;
  overflow: hidden;
  width: 100%;
  height: 45px;
  font-size: 14px;
  text-align: center;
}

.nearby-shop-wrapper .shop-list-wrap .owl-carousel .item .item-name_holder {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.nearby-shop-wrapper .shop-list-wrap .owl-carousel .item:hover {
  background-color: var(--light);
}

.nearby-shop-wrapper .shop-list-wrap .owl-carousel .item img {
  width: auto;
  height: 85px;
  border-radius: 10px;
}

.nearby-shop-wrapper .shop-list-wrap .owl-carousel .item .shop-name {
  font-size: 16px;
  font-weight: bold;
  margin: 10px 0 0 0;
  box-sizing: border-box;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow-wrap: break-word;
  -webkit-line-clamp: 1;
  overflow: hidden;
  width: 99%;
  text-align: center;
}

/* banner product item slide web  */
/*  */
.product-list-wrapper {
  width: 320px;
  height: 465px;
  position: absolute;
  top: 375px;
  right: 60px;
  overflow-y: hidden;
}

.carousel .slider-wrapper.axis-vertical {
  -ms-box-orient: horizontal;
  display: -moz-flex;
  display: flex;
  height: 410px !important;
}

.carousel .slider-wrapper.axis-vertical .slider {
  flex-direction: column;
  height: 130px !important;
}

/* .product-list-wrapper .carousel {
  position: relative;
  width: 100%;
  max-width: 500px;
  display: flex;
  justify-content: center;
  flex-direction: column;
} */

.product-list-wrapper .carousel__item {
  display: flex;
  align-items: center;
  position: absolute;
  width: 100%;
  padding: 0 12px;
  opacity: 0;
  /* filter: drop-shadow(0 2px 2px rgb(190, 190, 190)); */
  animation: animate 15s linear infinite;
  animation-delay: calc(3s * var(--delay));
}

.product-list-wrapper .carousel__item:hover {
  animation-play-state: paused;
}

.product-list-wrapper .wrapper .carousel__item:last-child {
  animation-delay: calc(-3s * var(--delay));
}

@keyframes animate {
  0% {
    opacity: 0;
    transform: translateY(100%) scale(0.5);
  }

  5%,
  20% {
    opacity: 0.4;
    transform: translateY(100%) scale(0.7);
  }

  25%,
  40% {
    opacity: 1;
    pointer-events: auto;
    transform: translateY(0%) scale(1);
  }

  45%,
  60% {
    opacity: 0.4;
    transform: translateY(-100%) scale(0.7);
  }

  65%,
  100% {
    opacity: 0;
    transform: translateY(-100%) scale(0.5);
  }
}

.banner-section .product-info {
  display: flex;
  background-color: white;
  border-radius: 20px;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  margin: 5px 5px 15px 5px;
  width: 310px;
  cursor: pointer;
}

/* .product-list-wrapper  .owl-carousel .owl-nav.disabled, .owl-carousel .owl-dots.disabled {
  display: block !important;
} */
/* 
.product-list-wrapper {
  transform: rotate(90deg);
}

.product-list-wrapper .product-list-item-wrapper {
  transform: rotate(-90deg);
} */

.product-list-wrapper .owl-carousel .owl-nav {
  display: flex;
  justify-content: space-between;
  position: absolute;
  width: 100%;
  top: calc(50% - 33px);
}

/* .product-list-wrapper .owl-carousel .owl-nav .owl-prev,
.product-list-wrapper .owl-carousel .owl-nav .owl-next {
  font-size: 36px;
  top: unset;
  bottom: 15px;
} */

.banner-section .product-info .item-img {
  width: 100px;
  height: auto;
  border-radius: 20px;
}

.banner-section .product-info .item-name_holder {
  padding: 5px 12px;
}

.banner-section .product-info .item-name {
  color: var(--gray);
  font-weight: 500;
  box-sizing: border-box;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow-wrap: break-word;
  -webkit-line-clamp: 1;
  overflow: hidden;
  width: 100%;
  margin-bottom: 3px;
}

.banner-section .product-info .description {
  color: var(--medium);
  font-size: 14px;
  margin-bottom: 5px;
  box-sizing: border-box;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow-wrap: break-word;
  -webkit-line-clamp: 2;
  overflow: hidden;
  width: 100%;
}

.banner-section .product-info .item-price {
  color: var(--white);
  font-size: 16px;
  font-weight: 700;
  background-color: var(--primary);
  border-radius: 30px;
  padding: 2px 14px;
}

/*  */

/* banner item slide mobile  */
.banner-section .item-info-mobile {
  display: flex;
  background-color: white;
  border-radius: 20px;
  box-shadow: 1px 1px 2px 1px rgb(0 0 0 / 10%);
  margin-top: 2rem;
  margin-bottom: 1rem;
}

.banner-section .item-info-mobile .item-img_holder {
  margin-right: 7px;
}

.banner-section .item-info-mobile .item-img {
  width: 90px;
  height: auto;
  border-radius: 15px;
}

.banner-section .item-info-mobile .item-name_holder {
  padding: 5px 12px;
}

.banner-section .item-info-mobile .item-name {
  color: var(--gray);
  font-weight: 500;
  box-sizing: border-box;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow-wrap: break-word;
  -webkit-line-clamp: 1;
  overflow: hidden;
  width: 99%;
}

.banner-section .item-info-mobile .description {
  color: var(--medium);
  font-size: 14px;
  margin-bottom: 12px;
  box-sizing: border-box;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow-wrap: break-word;
  -webkit-line-clamp: 2;
  overflow: hidden;
  width: 99%;
}

.banner-section .item-info-mobile .item-price {
  color: var(--white);
  font-size: 16px;
  font-weight: 700;
  background-color: var(--primary);
  border-radius: 30px;
  padding: 2px 14px;
}

/* react elestic carousel  */
.cVXxbE {
  background-color: #ff9a00 !important;
  box-shadow: 0 0 1px 3px #ffdba3 !important;
}

@media (max-width: 767.98px) {
  .home-header .header-logo img {
    height: 40px;
    width: auto;
  }

  .product-list-wrapper {
    display: none;
  }

  .product-list-wrapper-mobile {
    position: relative;
    display: block;
    box-sizing: border-box;
    height: 0;
    margin: 0;
    list-style-type: none;
    height: 145px !important;
    margin-bottom: 1rem;
  }

  .product-list-wrapper-mobile .carousel .slider-wrapper.axis-vertical {
    -ms-box-orient: horizontal;
    display: -moz-flex;
    display: flex;
    height: 140px !important;
  }

  .product-list-wrapper-mobile .jtKTCe {
    margin-top: 0;
  }

  .banner-section .product-list-wrapper-mobile .product-info {
    width: 100%;
  }

  .hand-img-two,
  .payment-web-img,
  .thai-qr-pay-web-img,
  .item-web-img,
  .wave-white-bg-img,
  .wave-img,
  .food-img-one,
  .food-img-two,
  .slide-top-wave-img,
  .slide-bottom-wave-img,
  .slide-wrapper .web-small-text,
  .banner-section .item-info {
    display: none;
  }

  .hand-img-one {
    position: absolute;
    top: 72px;
    left: -93px;
    width: 140px;
    height: auto;
  }

  .slide-wrapper {
    margin-top: 1.5rem;
    border-top-left-radius: 12rem;
    border-top-right-radius: 12rem;
    padding: 3rem 0 2rem 0;
    margin-bottom: 1.5rem;
    height: 750px;
  }

  .slide-img-two {
    margin-top: 1.75rem;
  }

  .slide-wrapper .title {
    font-size: 28px;
    margin-bottom: 1rem;
  }

  .slide-wrapper .mobile-small-text {
    display: block;
    color: var(--white);
    font-size: 14px;
    font-weight: normal;
    margin-bottom: 1.5rem;
  }

  .slide-wrapper .button-group {
    flex-direction: column;
  }

  .slide-wrapper .button-group .btn-white {
    margin-bottom: 1rem;
  }

  .slide-wrapper .slide-img-two,
  .slide-wrapper .slide-img-three {
    height: auto;
    margin-bottom: 1.5rem;
  }

  .food-img-three {
    width: 70px !important;
    height: auto;
    top: 736px !important;
    left: 320px !important;
  }

  .slide-wrapper .owl-theme .owl-dots {
    left: 10px !important;
    bottom: -45px !important;
  }

  .banner-section h1 {
    font-size: 25px;
  }

  .banner-section #shop-img {
    width: 100%;
    height: auto;
  }

  .why-use-section {
    border-radius: 2rem;
    padding-bottom: 2rem;
  }

  .service-info {
    margin-bottom: 1.5rem;
  }

  .service-section h4 {
    margin-top: 2.5rem;
    margin-bottom: 0.5rem;
  }

  .service-info .service-img_wrap {
    height: auto;
  }

  .service-info .service-name {
    margin-top: 15px;
  }

  .footer .underline {
    border-bottom: 2px solid var(--mediumLight);
    margin: 10px 0 20px 0;
  }
}

@media (min-width: 768px) {
  .mobile-payment-img-one,
  .mobile-payment-img-two,
  .mobile-item-img-one,
  .mobile-small-text {
    display: none;
  }

  .banner-section #shop-img {
    width: 100%;
    height: auto;
  }

  .slide-top-wave-img {
    width: 100%;
    margin-bottom: -4rem;
  }

  .food-img-one {
    position: absolute;
    width: 170px;
    height: auto;
    top: -18px;
    left: 25px;
  }

  .food-img-two {
    position: absolute;
    width: 150px;
    height: auto;
    top: 0px;
    right: 25px;
  }

  .food-img-three {
    position: absolute;
    width: 140px;
    height: auto;
    top: -20px;
    right: 25px;
  }

  .why-use-section {
    padding: 1.5rem 0 2.5rem 0;
  }

  .wave-img {
    margin-top: -1rem;
  }

  .service-info .service-name {
    margin-bottom: 1rem;
  }
}

@media (max-width: 992px) {
  .hand-img-one {
    position: absolute;
    top: 47px;
    left: -104px;
    width: 145px;
    height: auto;
  }

  .hand-img-two {
    display: none;
  }

  /* .item-slide-wrapper.web-item-img .owl-theme .owl-dots {
    right: 114px !important;
    top: 197px !important;
  } */

  .banner-section .payment-web-img {
    height: 190px;
    position: absolute;
    top: 187px;
    left: 40px;
  }

  .banner-section .item-web-img {
    width: 200px;
    top: 295px;
    right: 56px;
  }

  .banner-section .item-info {
    width: 200px;
    top: 295px;
    right: 56px;
  }

  .slide-wrapper .button-group {
    flex-direction: column;
  }

  .slide-wrapper .button-group .btn-white {
    margin-bottom: 1rem;
  }

  .slide-wrapper .title {
    font-size: 33px;
  }

  .slide-wrapper .web-small-text {
    font-size: 13px;
  }

  .slide-wrapper .owl-theme .owl-dots {
    left: 40px;
    bottom: -50px;
  }

  .banner-section .item-info {
    width: 250px;
    top: 320px;
    right: 45px;
  }

  .banner-section .item-info .item-img {
    width: 90px;
    height: auto;
    border-radius: 10px;
  }

  .banner-section .item-info .description {
    -webkit-line-clamp: 1;
  }

  .banner-section .item-info .description {
    margin-bottom: 6px;
  }

  .banner-section .item-info .item-price {
    font-size: 12px;
  }

  .banner-section h1 {
    font-size: 27px;
  }
}

@media (min-width: 992px) {
  .navbar-nav {
    flex-direction: row;
  }

  .banner {
    height: 530px;
  }

  .food-img-three {
    top: 585px;
  }

  .why-use-section .img-wrapper {
    height: auto;
    text-align: center;
  }

  .hand-img-one {
    top: -62px;
    left: -83px;
    width: 145px;
  }

  .hand-img-two {
    top: -106px;
    right: -20px;
  }

  .banner-section .payment-web-img {
    height: 275px;
    top: 110px;
    left: 90px;
  }

  .banner-section .thai-qr-pay-web-img {
    position: absolute;
    width: 55px;
    top: 135px;
    right: 100px;
  }

  .banner-section .item-web-img {
    width: 245px;
    right: 105px;
  }

  .item-slide-wrapper.web-item-img {
    margin-top: 7rem;
  }

  .item-slide-wrapper.web-item-img .owl-theme .owl-dots {
    right: 180px;
    top: 275px;
  }

  .slide-wrapper .button-group .btn-white {
    margin-bottom: 1rem;
  }

  .slide-wrapper .owl-theme .owl-dots {
    left: 50px;
    bottom: 10px;
  }
}

@media screen and (min-width: 1200px) {
  .slide-wrapper .button-group {
    flex-direction: row;
  }

  .slide-wrapper .owl-theme .owl-dots {
    left: 114px;
    bottom: 10px;
  }

  .item-slide-wrapper.web-item-img .owl-theme .owl-dots {
    position: absolute;
    right: 244px;
    top: 335px;
  }

  .banner-section .item-info {
    width: 315px;
    right: 80px;
  }
}

/* QTY NUMERICE INPUT CSS */
.qty-input-holder .input-spinner {
  width: 100px;
  background-color: #f9f9f9;
  border-radius: 20px;
}

.qty-input-holder .form-control {
  text-align: center;
  background-color: #f9f9f9;
  border-color: transparent;
  font-size: 13px;
}

.qty-input-holder .form-control:focus {
  box-shadow: none;
}

.qty-input-holder .btn {
  width: 30px;
}

.qty-input-holder .input-group-sm > .input-group-prepend > .btn,
.input-group-sm > .input-group-prepend > .input-group-text {
  border-radius: 50%;
  margin-right: 3px;
}

.qty-input-holder .input-group > .input-group-append > .btn {
  border-top-left-radius: 50%;
  border-bottom-left-radius: 50%;
}

.qty-input-holder .input-group-sm > .custom-select,
.input-group-sm > .form-control,
.input-group-sm > .input-group-append > .btn,
.input-group-sm > .input-group-append > .input-group-text,
.input-group-sm > .input-group-prepend > .btn,
.input-group-sm > .input-group-prepend > .input-group-text {
  border-radius: 50%;
}

/*  */
.cart-item .product-list-item,
.set-time-wrap select,
.css-1s2u09g-control,
.modal-custom .scroll-container .product-list-item,
.modal-custom.product-list-modal .product-detail-modal-header .back-link,
.modal-custom.product-list-modal .product-detail-modal-header .close-icon {
  cursor: pointer;
}

.cart-holder .icon-holder .p-list-nav-icon {
  font-size: 1.2rem;
}

.add-more {
  color: #ff6b00;
  cursor: pointer;
}

.btn-primary-round {
  background-color: #f6a23a;
  color: var(--white);
  padding: 0.8rem 2rem;
  font-size: 17px;
  font-weight: bold;
  border-radius: 2rem;
  box-shadow: 0 3px 12px 0 rgba(255, 161, 9, 0.589);
}

.btn-primary-round:hover {
  background-color: #f6a23a !important;
  box-shadow: none;
}

.product-list-wrapper .rec-dot_active,
.product-list-wrapper-mobile .rec-dot_active {
  background-color: #ff9a00;
  box-shadow: 0 0 1px 3px #ffbe5b;
}

.product-list-wrapper .rec-dot_active:hover,
.product-list-wrapper .rec-dot_active:focus,
.product-list-wrapper-mobile .rec-dot_active:hover,
.product-list-wrapper-mobile .rec-dot_active:focus {
  box-shadow: 0 0 1px 3px #ff9a00 !important;
}

.product-list-wrapper .rec-dot:hover,
.product-list-wrapper .rec-dot:focus,
.product-list-wrapper-mobile .rec-dot:hover,
.product-list-wrapper-mobile .rec-dot:focus {
  box-shadow: 0 0 1px 3px #ffbe5b;
}

.review-score {
  margin-left: 7px;
  font-weight: bold;
  color: var(--medium);
}

.review-star-img {
  width: auto;
  height: 18px !important;
}

.no-review {
  opacity: 0.5;
}

.reg-page-bg {
  background-color: white;
}

.reg-page {
  background: url("assets/image/bg-graphic.png") no-repeat center center;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: 1400px;
  background-color: #f8f9fa;
  padding-top: 2rem;
}

.reg-wave-gray-bg-img {
  width: 100%;
  height: auto;
  margin-bottom: -2rem;
}

/*  */
.pos-payment-success .card {
  margin-top: 1rem;
}

.pos-payment-success .card-header {
  background-color: white !important;
}

.pos-payment-success .underline {
  border-bottom: 1px solid #e6e6e6;
  margin-bottom: 10px;
}

.pos-payment-success .shop-name {
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 7px;
}

.pos-payment-success .item-name,
.pos-payment-success .item-price,
.pos-payment-success .date,
.pos-payment-success .time {
  font-size: 15px;
  font-weight: 400;
  margin-bottom: 5px;
}

.pos-payment-success .item-price {
  text-align: right;
}

.pos-payment-success .shop-address,
.pos-payment-success .branch-name {
  font-size: 15px;
  font-weight: 400;
  margin-bottom: 5px;
}

.pos-payment-success .shop-address {
  margin-bottom: 1.5rem;
}

.pos-payment-success .receipt {
  font-size: 18px;
  font-weight: bold;
  margin-top: 10px;
  margin-bottom: 7px;
}

.pos-payment-success .staff {
  font-size: 15px;
  font-weight: 500;
  margin-bottom: 5px;
}

.pos-payment-success .tel {
  font-size: 15px;
  font-weight: 400;
  margin-bottom: 10px;
}

.pos-payment-success .total-price {
  font-size: 16px;
  font-weight: 400;
}

.q-number-text {
  flex-direction: row;
  align-items: center;
  padding: 2px 7px;
  border-radius: 5px;
  border: 0.7px solid #2cc75f;
  background-color: #2cc75f;
  color: white !important;
  font-size: 12px;
  font-weight: bold;
  margin-right: 6px;
}

.q-number {
  position: absolute;
  right: 17px;
  bottom: 85px;
}

.status-wrapper .q-number-text {
  padding: 1px 7px;
}

.history-list .btn-review {
  padding: 2px 7px;
  font-size: 12px;
}

.product-list-cart-count {
  /* top: 7px;
  right: -2px;
  padding: 2px 6px;
  border-radius: 12px;
  color: #ffffff;
  background-color: #ff7700;
  font-size: 10px;
  font-weight: bold;
  line-height: 1.4;
  z-index: 2;
  width: 19px; */
}

.plus-circle-icon {
  position: absolute;
  top: 79px;
  right: 7px;
  background-color: #ff9a00;
  color: #ffffff;
  font-size: 10px;
  font-weight: 500;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  box-shadow: 0px 2px 7px rgba(0, 0, 0, 0.2);
  cursor: pointer;
}

.product-list-cart-count {
  position: absolute;
  right: 7px;
  bottom: 8px;
  background-color: #ffffff;
  color: #ff9a00;
  font-size: 10px;
  font-weight: bold;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0px 2px 7px rgba(0, 0, 0, 0.2);
  cursor: pointer;
}

.product-item_attr {
  font-size: 12px;
  color: #676767;
  padding: 3px 9px;
  background: #f9f9f9;
  border-radius: 4px;
  display: inline-block;
}

.backdrop {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1050;
  width: 100vw;
  height: 100vh;
  background-color: #000;
  opacity: 0.5;
}

.backdrop-loading-img {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  margin: auto;
  z-index: 9999;
}
