@tailwind base;
@tailwind components;
@tailwind utilities;
@import "slick-carousel/slick/slick.css";
@import "slick-carousel/slick/slick-theme.css";

@import url("https://fonts.googleapis.com/css2?family=Kanit:wght@300&display=swap");

@import url("https://fonts.googleapis.com/css2?family=IBM+Plex+Sans+Thai:wght@400;500;600;700&family=Roboto:wght@300;400;500;700;900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=IBM+Plex+Sans+Thai:wght@400;500;600;700&family=Lato:wght@300;400;700;900&family=Roboto:wght@300;400;500;700;900&display=swap");

body {
  margin: 0;
  padding: 0;
  overflow-y: auto;
  overflow-x: hidden;
  font-family: "IBM Plex Sans Thai", sans-serif;
  font-size: 15px;
  background-color: #f8f9fa;
}

/* styles.css (or your component-specific CSS file) */
@keyframes shake {
  0% {
    transform: translateX(0);
  }
  25% {
    transform: translateX(-2px);
  }
  50% {
    transform: translateX(2px);
  }
  75% {
    transform: translateX(-2px);
  }
  100% {
    transform: translateX(2px);
  }
}

.shake {
  animation: shake 0.5s ease infinite; /* Adjust the animation duration as needed */
}

input::-ms-reveal,
input::-ms-clear {
  display: none;
}
