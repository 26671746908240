/* Initial style for the collapsed title */
.collapsed-title {
  font-size: 15px;
  transition: font-size 0.3s, color 0.3s; /* Add transition effect */
}

/* Style for the expanded title */
.expanded-title {
  font-size: 15px; /* Increase font size when expanded */
  color: #ff961b; /* Change color when expanded */
  transition: font-size 0.3s, color 0.3s; /* Add transition effect */
}
